import { useState } from 'react';
import { Features, FeaturesStore } from 'stores/features';

function useIsFeatureEnabled(feature: Features): boolean {
  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  FeaturesStore.useState((s) => {
    setIsEnabled(s[feature]);
  });
  return isEnabled;
}

export default useIsFeatureEnabled;
