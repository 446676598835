import { CurrentConversationStore } from 'stores/current-conversation';

export type FileType = {
  datetimeCreated: string;
  datetimeLastUpdated: string;
  displayFileName: string;
  id: string;
  originalFileUrl: string;
  privacyLevel: string;
  processedFileUrl: string;
  processingFailureComment: string;
  processingStatus: string;
  userId: string;
};

export const hasFilesInProgress = (files: FileType[]): boolean => {
  return files.some((file) => file.processingStatus === 'in progress');
};

export const filterFilesInProgress = (files: FileType[]): FileType[] => {
  return files.filter((file) => file.processingStatus === 'in progress');
};

export const mapFileIdToFile = (
  fileId: string,
  files: FileType[],
): FileType | {} => {
  return files.find((file) => file.id === fileId) || {};
};

export const diffCurrentPendingFiles = (
  personalFiles: FileType[],
  fileContexts: string[],
): FileType[] => {
  const messages = CurrentConversationStore.getRawState().messages;

  const uniqFilesAlreadyApplied = new Set(
    messages.map((message) => message.associatedFileRecordIds).flat(),
  );

  const pendingFileIds = fileContexts.filter(
    (id) => !uniqFilesAlreadyApplied.has(id),
  );

  return personalFiles.filter((file) => pendingFileIds.includes(file.id));
};

export const getUniqueSelectedFileContextIdsFromMessages = (
  messages: any[],
) => {
  const selectedFileIds = (messages as any).reduce((acc, m) => {
    if (m.associatedFileRecordIds) {
      return [...acc, ...m.associatedFileRecordIds];
    }
    return acc;
  }, []);
  return Array.from(new Set(selectedFileIds));
};

export const sortFilesByLastUpdated = (files: FileType[]) => {
  return files.sort(
    (a, b) =>
      new Date(a.datetimeLastUpdated).getTime() -
      new Date(b.datetimeLastUpdated).getTime(),
  );
};
