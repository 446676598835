import React from 'react';
import { Progress } from 'antd';

const ProgressBar = ({ percent }) => {
  return (
    <div className="progress-bar" role="progressbar">
      <Progress
        percent={percent}
        size={[300, 10]}
        showInfo={false}
        strokeColor={'#34C759'}
        trailColor={'#FFFFFF'}
      />
    </div>
  );
};

export default ProgressBar;
