import * as React from 'react';
import { Modal as AntModal } from 'antd';
import './modal.css';

const Modal: React.FC<{
  title?: React.ReactNode;
  open: boolean;
  onOk?: () => void;
  onCancel?: () => void;
  footer?: React.ReactNode | (() => void); // Added parentheses around the function type notation
  children?: React.ReactNode;
  className?: string;
  mask?: boolean;
  maskClosable?: boolean;
  destroyOnClose?: boolean;
  closeIcon?: boolean;
}> = ({
  title = undefined,
  open,
  onOk = () => {},
  onCancel = () => {},
  footer,
  children,
  className = '',
  mask = true,
  maskClosable = true,
  destroyOnClose = true,
  closeIcon = true,
}) => {
  return (
    <AntModal
      className={className}
      title={title}
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      footer={footer as React.ReactNode}
      getContainer="#modal-root"
      mask={mask}
      maskClosable={maskClosable}
      destroyOnClose={destroyOnClose}
      closeIcon={closeIcon}
    >
      {children}
    </AntModal>
  );
};

export default Modal;
