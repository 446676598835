import React, { useEffect, useState, useCallback } from 'react';
import { useDebounceCallback } from 'usehooks-ts';

import api from 'services/api';
import useGlobalNotification from 'services/hooks/useGlobalNotification';

import { Button } from 'ui/atoms';
import { FeedbackModal } from './FeedbackModal';

import { ReactComponent as ClipboardIcon } from './clipboard.svg';
import { ReactComponent as ThumbsUpIcon } from './thumbsup.svg';
import { ReactComponent as ThumbsDownIcon } from './thumbsdown.svg';

import './response-feedback.css';

export type ReactionType = 'like' | 'dislike' | undefined;

export type ResponseFeedbackPropType = {
  updateReaction: (reaction: ReactionType) => void;
  defaultReaction?: ReactionType;
  message?: any;
  conversationId?: any;
};

const ResponseFeedback: React.FC<ResponseFeedbackPropType> = ({
  updateReaction,
  defaultReaction,
  message,
  conversationId,
}) => {
  const [currentReaction, setCurrentReaction] = useState(defaultReaction);
  const [toggleFeedbackModal, setToggleFeedbackModal] = useState(false);

  const changeUpdatedReaction = useCallback(
    (payload: any) => {
      api
        .updateMessageMetadata({
          conversationId,
          message,
          payload,
        })
        .then((response) => {
          if (!response) {
            return;
          }

          // eslint-disable-next-line
          message = {
            ...message,
            datetimeLastUpdated: response?.datetimeLastUpdated,
          };
        });
    },
    [message, conversationId],
  );

  const { openNotification, notificationContextHolder } =
    useGlobalNotification();

  const changeCurrentReaction = (reaction: ReactionType) => {
    setCurrentReaction(reaction);

    if (reaction === currentReaction) {
      setCurrentReaction(undefined);

      return changeUpdatedReaction({
        userFeedback: { feedbackType: null },
      });
    }

    if (reaction === 'like') {
      changeUpdatedReaction({
        userFeedback: {
          feedbackType: reaction,
        },
      });

      return openNotification({
        message:
          'Thank you! Your feedback will be shared with our development team to help improve Goldie. Chats with private files will not be evaluated. Unselect this action if you prefer not to share.',
        duration: 10,
      });
    }

    setToggleFeedbackModal(!toggleFeedbackModal);
  };

  const copyCurrentMessageToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(message?.message || '');
      return openNotification({
        placement: 'top',
        message: 'Copied to clipboard.',
      });
    } catch (error) {
      console.error('Failed to copy: ', error);
    }
  };

  const debounceChangeReaction = useDebounceCallback(
    changeCurrentReaction,
    300,
  );

  useEffect(() => {
    if (typeof updateReaction === 'function') {
      updateReaction(currentReaction);
    }
  }, [currentReaction, updateReaction]);

  return (
    <div className="response-feedback" data-testid="response-feedback">
      {notificationContextHolder}
      <Button
        className="clipboard-action"
        type="text"
        shape="circle"
        onClick={copyCurrentMessageToClipboard}
        icon={<ClipboardIcon />}
        data-testid="response-feedback-copy"
      />
      <Button
        type="text"
        shape="circle"
        onClick={() => debounceChangeReaction('like')}
        isActive={currentReaction === 'like'}
        data-testid="response-feedback-up"
        icon={<ThumbsUpIcon />}
      />
      <Button
        type="text"
        shape="circle"
        onClick={() => debounceChangeReaction('dislike')}
        isActive={currentReaction === 'dislike'}
        data-testid="response-feedback-down"
        icon={<ThumbsDownIcon />}
      />
      <FeedbackModal
        setToggleFeedbackModal={setToggleFeedbackModal}
        toggleFeedbackModal={toggleFeedbackModal}
        changeUpdatedReaction={changeUpdatedReaction}
        openNotification={openNotification}
      />
    </div>
  );
};

export default ResponseFeedback;
