import { useMemo } from 'react';
import { SilentRequest } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';

import config from 'config';
import { setCurrentAccount } from 'stores/auth';

export const silentRequestBaseConfig = {
  scopes: config.scopes,
  authority: `${config.microsoftLoginUrl}/${config.tenantId}`,
  forceRefresh: false,
  redirectUri: window.location.origin,
};

function useSilentRequest(): SilentRequest {
  const { accounts } = useMsal();
  const account = accounts[0];
  setCurrentAccount(account);
  return useMemo(
    () => ({
      account,
      ...silentRequestBaseConfig,
    }),
    [account],
  );
}

export default useSilentRequest;
