export const sortByName = (array) => {
  return array.sort((a, b) => {
    let nameA = a.name.toLowerCase(); // Use toLowerCase() for case-insensitive comparison
    let nameB = b.name.toLowerCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
};
