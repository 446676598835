export const getInitials = (name) => {
  if (!name) {
    return '';
  }
  const splitName = name
    .replace(/\([^)]*\)/g, '')
    .trim('')
    .split(' ');
  const initials =
    splitName.length === 1
      ? splitName[0].charAt(0)
      : `${splitName[0].charAt(0)}${splitName.reverse()[0].charAt(0)}`;

  return initials.toUpperCase().split('').reverse().join('');
};
