import React from 'react';
import { Checkbox } from 'antd';
import classNames from 'classnames';

import './checkbox-selection.css';

type CheckboxSelectionProps = {
  value: string;
  label: string;
  header?: string;
  sublabel?: string;
  isChecked: boolean;
  setSelected: (e: any) => void;
  disabled?: boolean;
  className?: string;
};

const CheckboxSelection: React.FC<CheckboxSelectionProps> = ({
  value,
  label,
  sublabel = null,
  header = null,
  isChecked,
  setSelected,
  disabled = false,
  className = '',
}) => {
  return (
    <div
      className={classNames(className, 'checkbox-selection', {
        disabled: disabled,
      })}
    >
      <label htmlFor={`checkbox_${value}`} className="label-text">
        {header && <div className="header">{header}</div>}
        {label}
        {sublabel && <div className="sublabel">{sublabel}</div>}
      </label>{' '}
      <Checkbox
        id={`checkbox_${value}`}
        disabled={disabled}
        checked={isChecked}
        onChange={(e) => {
          setSelected(e);
        }}
      ></Checkbox>
    </div>
  );
};

export default CheckboxSelection;
