/* eslint no-console: "off" */

import { retrieveValueSync } from 'services/local';
import { LocalStorageKey } from 'utils/localstorage';
import config from 'config';

const logBit = retrieveValueSync(LocalStorageKey.showLogs);
const isDev = config.env === 'development';
const shouldLog = !isDev && logBit === 1;

export const prodLogger = {
  info: (...args) => {
    if (shouldLog) {
      console.log(...args);
    }
  },
  error: (...args) => {
    if (shouldLog) {
      console.error(...args);
    }
  },
};
