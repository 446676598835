export interface Config {
  aidaBasePath: string;
  clientId: string;
  tenantId: string;
  scopes: string[];
  microsoftLoginUrl: string;
  env: string;
  datadogClientToken?: string;
  datadogSite?: string;
}

const windowConfig = (window as any).config || {
  aidaBasePath: '',
  clientId: '',
  tenantId: '',
  scopes: '[]',
  microsoftLoginUrl: '',
  env: 'development',
};

const config: Config = {
  ...windowConfig,
  scopes: JSON.parse(windowConfig.scopes),
  env: process.env.NODE_ENV || 'development',
};

export default config;
