import { devLogger } from 'services/logs/transports/dev-transport';
import { prodLogger } from 'services/logs/transports/prod-transport';

export const debug = (...logs) => {
  devLogger.debug(...logs);
  prodLogger.info(...logs);
};

export const log = (...logs) => {
  devLogger.info(...logs);
  prodLogger.info(...logs);
};

export const error = (...logs) => {
  devLogger.error(...logs);
  prodLogger.error(...logs);
};

export const logger = {
  log,
  error,
  info: log,
  debug,
};
