import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { Button } from '../../atoms';
import { ReactComponent as ChatIcon } from './chat.svg';
import { ReactComponent as CancelIcon } from './cancel.svg';
import { ReactComponent as SaveIcon } from './save.svg';
import { ReactComponent as TrashIcon } from './trash.svg';
import { ReactComponent as EditIcon } from './edit.svg';

import './conversation-history-item.css';
import { CurrentConversationStore } from 'stores/current-conversation';
import {
  encodeStringToHtmlEntities,
  decodeStringFromHtmlEntities,
  stripHtmlFromString,
} from 'utils/string-utils';

const ConversationHistoryItem = ({
  text,
  id,
  deleteConversation,
  setCurrentConversation,
  renameConversation,
}) => {
  const [editHover, setEditHover] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState(text);
  const [isSaving, setIsSaving] = useState(false);

  const currentConversationId = CurrentConversationStore.useState((s) => s.id);

  useEffect(() => {
    if (currentConversationId !== id) {
      setEditMode(false);
      setEditHover(false);
    }
  }, [currentConversationId, id]);

  useEffect(() => {
    if (editMode) {
      const inputElement = inputRef.current as any;
      if (inputElement) {
        inputElement.focus();
        inputElement.scrollLeft = inputElement.scrollWidth;
      }
    }
  }, [editMode]);

  return (
    <div
      className={classNames([
        'conversation-history-item',
        { editmode: editHover || editMode },
      ])}
    >
      <div className="conversation-history-item-button">
        <button
          className="conversation-name"
          onClick={() => {
            setCurrentConversation(id);
            setEditHover(!editHover);
          }}
        >
          <ChatIcon />
          <div className="conversation-name-text">
            {!editMode && (
              <span
                dangerouslySetInnerHTML={{ __html: stripHtmlFromString(text) }}
              ></span>
            )}
            {editMode && (
              <input
                aria-label="Edit Conversation Name Input"
                ref={inputRef}
                maxLength={64}
                type="text"
                defaultValue={decodeStringFromHtmlEntities(inputValue)}
                onBlur={(e) => {
                  e.stopPropagation();
                  setInputValue(encodeStringToHtmlEntities(e.target.value));
                }}
              />
            )}
          </div>
        </button>

        <div
          className="menu"
          tabIndex={0} // Makes the div focusable
          role="menu"
          onClick={() => {
            setCurrentConversation(id);
            setEditHover(!editHover);
          }}
          onKeyDown={() => {
            setEditHover(!editHover);
          }}
        >
          {(editHover || editMode) && (
            <>
              {!editMode && (
                <>
                  <Button
                    aria-label="Edit Conversation Name"
                    icon={<EditIcon />}
                    type="link"
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditMode(true);
                    }}
                  />
                  <Button
                    aria-label="Delete Conversation"
                    icon={<TrashIcon />}
                    type="link"
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteConversation(id);
                    }}
                  />
                </>
              )}
              {editMode && (
                <>
                  <Button
                    aria-label="Save Conversation Name"
                    icon={<SaveIcon />}
                    className="save-button"
                    type="link"
                    disabled={isSaving}
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsSaving(true);
                      (async () => {
                        await renameConversation(id, inputValue);
                        setTimeout(() => {
                          setEditMode(false);
                          setIsSaving(false);
                        }, 800);
                      })();
                    }}
                  />
                  <Button
                    icon={<CancelIcon />}
                    type="link"
                    disabled={isSaving}
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditMode(false);
                    }}
                  />
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConversationHistoryItem;
