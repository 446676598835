import { MarkdownRenderer } from 'ui/molecules/MarkdownRenderer';

import './conversationresponserow.css';

const ConversationResponseRow = ({
  message,
  profileImg,
  isError = false,
  allowRetry = false,
}) => {
  return (
    <div className="message">
      <div className={`response${isError ? ' error' : ''}`}>
        <img src={profileImg} alt="profile-pic" />
        {!isError && <MarkdownRenderer text={message} />}
        {isError && (
          <span className="error-message">
            We're having a little trouble generating a response.{' '}
            {allowRetry && (
              <span>Would you like to try regenerating that response?</span>
            )}
          </span>
        )}
      </div>
    </div>
  );
};

export default ConversationResponseRow;
