import React from 'react';
import { StrykerLogo } from 'ui';

import './error.css';

const ErrorPage = () => {
  return (
    <main data-testid="error-container" className="error-page">
      <StrykerLogo />
      <h1>
        Something went wrong. <a href="/">Please try again later</a>.
      </h1>
    </main>
  );
};

export default ErrorPage;
