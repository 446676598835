import { Store } from 'pullstate';

type AuthStoreType = {
  MSALInstance: any;
  accessToken: boolean;
  currentAccount: any;
};

const initialState: AuthStoreType = {
  MSALInstance: null,
  accessToken: false,
  currentAccount: null,
};

export const AuthStore = new Store(initialState);

export const setAuthInstance = (instance) => {
  AuthStore.update((s) => {
    s.MSALInstance = instance;
    return s;
  });
};

export const setCurrentAccount = (account) => {
  AuthStore.update((s) => {
    s.currentAccount = account;
    return s;
  });
};

export const updateAccessToken = (token) => {
  AuthStore.update((s) => {
    s.accessToken = token;
    return s;
  });
};
