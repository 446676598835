import React, { useState } from 'react';
import { Input } from 'antd';

import { Button, Modal } from 'ui/atoms';
import CheckboxSelection from 'ui/atoms/CheckboxSelection';

import './response-feedback.css';

export type FeedbackType = 'isHarmful' | 'isNotTrue' | 'isNotHelpful';
export type FeedbackOption = {
  header: string;
  label: string;
  value: FeedbackType;
  checked: boolean;
};

export const FeedbackModal = ({
  setToggleFeedbackModal,
  toggleFeedbackModal,
  changeUpdatedReaction,
  openNotification,
}) => {
  const { TextArea } = Input;
  const [textAreaValue, setTextAreaValue] = useState('');
  const [feedbackOptions, setFeedbackOptions] = useState<FeedbackOption[]>([
    {
      header: 'Response was incorrect.',
      label: 'It contains an error or other wrong information.',
      value: 'isNotHelpful',
      checked: false,
    },
    {
      header: 'Response was irrelevant.',
      label:
        'It contains a hallucination or other content not relevant to the prompt.',
      value: 'isNotTrue',
      checked: false,
    },
    {
      header: 'Response was unsafe',
      label:
        'It contains harmful content or something that should not have been said.',
      value: 'isHarmful',
      checked: false,
    },
  ]);

  const submitFeedback = () => {
    const types = feedbackOptions
      .filter((f) => f.checked)
      .map((t) => t.value)
      .map((t) => {
        return { [t]: true };
      });

    const feedbackContent = [
      {
        feedbackType: 'dislike',
        feedbackContent: textAreaValue,
      },
    ];

    const feedbackPayload = Object.assign({}, ...feedbackContent, ...types);

    changeUpdatedReaction({ userFeedback: feedbackPayload });
    setToggleFeedbackModal(false);
    openNotification({
      message:
        'Thank you! Your feedback will be shared with our development team to help improve Goldie. Chats with private files will not be evaluated. Unselect this action if you prefer not to share.',
      duration: 10,
    });
    clearFeedbackForm();
  };

  const clearFeedbackForm = () => {
    setTextAreaValue('');
    setFeedbackOptions((prev) => {
      return prev.map((item) => ({
        ...item,
        checked: false,
      }));
    });
  };

  const updateTypeCheckedState = (value: FeedbackType) => {
    const updatedFeedbackOptions = feedbackOptions.map((item) => {
      if (item.value === value) {
        return { ...item, checked: !item.checked };
      }

      return item;
    });

    setFeedbackOptions(updatedFeedbackOptions);
  };

  return (
    <Modal
      title={'Provide Additional Feedback'}
      open={toggleFeedbackModal}
      className="feedback-modal"
      onOk={() => {}}
      onCancel={() => {
        setToggleFeedbackModal(false);
        clearFeedbackForm();
      }}
      footer={[
        <Button
          key=""
          className="active full-size"
          onClick={() => submitFeedback()}
        >
          Submit Feedback
        </Button>,
      ]}
    >
      <div className="feedback-modal-textarea">
        <TextArea
          placeholder="What was the issue with the response? How could it be improved?"
          value={textAreaValue}
          onChange={(e) => setTextAreaValue(e.target.value)}
        />
      </div>

      <div className="feedback-modal-types">
        {feedbackOptions.map(({ value, header, label, checked }) => {
          return (
            <CheckboxSelection
              key={value}
              header={header}
              value={value}
              label={label}
              isChecked={checked}
              setSelected={() => {
                updateTypeCheckedState(value);
              }}
            />
          );
        })}
      </div>
    </Modal>
  );
};
