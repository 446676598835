import React, { useEffect } from 'react';
import { IDLE_TIMEOUT_CONFIRMATION } from 'stores/metadata';
import { ConfirmationDialog } from 'ui/molecules';
import { stringifyIdleConfirmationValue } from 'utils/time-utils';

const confirmTimeoutCountdown = IDLE_TIMEOUT_CONFIRMATION / 1000; // seconds
let countdown = confirmTimeoutCountdown;
let countdownInterval;

const ConfirmTimeout = ({
  confirmTimeoutOpen,
  confirmTimeoutFunction,
  confirmTimeoutCancel = () => {},
}) => {
  const [timeLeft, setTimeLeft] = React.useState(confirmTimeoutCountdown);

  useEffect(() => {
    if (confirmTimeoutOpen) {
      if (confirmTimeoutCountdown <= 0) {
        confirmTimeoutFunction(false);
      }
      if (countdown === confirmTimeoutCountdown) {
        clearInterval(countdownInterval);
        countdownInterval = setInterval(() => {
          countdown = countdown - 1;
          setTimeLeft(countdown);
          if (countdown === 0) {
            clearInterval(countdownInterval);
            confirmTimeoutFunction(false);
          }
        }, 1000) as unknown as null;
      }
    } else {
      countdown = confirmTimeoutCountdown;
      clearInterval(countdownInterval);
      countdownInterval = null;
    }
  }, [confirmTimeoutFunction, confirmTimeoutOpen]);

  if (!confirmTimeoutOpen) {
    return null;
  }

  return (
    <ConfirmationDialog
      title="Still with us?"
      text={`You've been inactive for a while. For your security, your session will expire in ${stringifyIdleConfirmationValue(
        timeLeft,
      )}.`}
      open={confirmTimeoutOpen}
      maskClosable={false}
      confirmText="Keep using Goldie"
      cancelText="Sign out"
      reportResponse={(hasConfirmed) => {
        confirmTimeoutFunction(hasConfirmed);
      }}
      confirmDanger={false}
      onCancelCallback={confirmTimeoutCancel}
    />
  );
};

export default ConfirmTimeout;
