import React from 'react';
import './radio-selection.css';

interface RadioSelectionProps {
  label: string;
  name: string;
  value: string;
  description?: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  cssClasses?: string;
}

const RadioSelection: React.FC<RadioSelectionProps> = ({
  label,
  name,
  value,
  description,
  checked,
  onChange,
  cssClasses,
}) => {
  return (
    <div className={`radio-selection ${cssClasses}`}>
      <div>
        <label htmlFor={`${name}_${value}`}>{label}</label>
        {description && (
          <p className="radio-selection-description">{description}</p>
        )}
      </div>
      <div className="radio-selection-input">
        <input
          type="radio"
          id={`${name}_${value}`}
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default RadioSelection;
