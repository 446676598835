import { Store } from 'pullstate';
import { getInitials } from 'utils/initials';
import api from 'services/api';
import { persistSessionValue, retrieveSessionValue } from 'services/local';
import { logger } from 'services/logs/logger';
import { LocalStorageKey } from 'utils/localstorage';
import {
  FILE_UPLOAD_MAXIMUM_WARNING,
  FILE_UPLOAD_MONTHLY_LIMIT,
} from './metadata';
import { filterFilesInProgress, hasFilesInProgress } from 'utils/file-utils';
import { appendToFileContexts } from './current-conversation';

export const NIL_UUID = '00000000-0000-0000-0000-000000000000';

type UserInfo = {
  name: string;
  email: string;
  initials: string;
};

type UserStoreType = {
  requestedAuth: boolean;
  isAuthed: boolean;
  userHasLoaded: boolean;
  apiConfig: any;
  personalFiles: any[];
  hasPendingFiles: boolean;
  userInfo: UserInfo | null;
  agreedToTerms: boolean;
  viewedSplash: boolean;
  currentSession?: string;
  documentsRemaining: number;
};

const initialState: UserStoreType = {
  requestedAuth: false,
  isAuthed: false,
  userHasLoaded: false,
  apiConfig: null,
  personalFiles: [],
  hasPendingFiles: false,
  userInfo: null,
  agreedToTerms: false,
  viewedSplash: false,
  currentSession: NIL_UUID,
  documentsRemaining: FILE_UPLOAD_MONTHLY_LIMIT,
};

const FILE_POLLING_INTERVAL = 3000;

export const UserStore = new Store(initialState);

let fileInterval;
let pendingFilesContexts = [] as any[];

UserStore.createReaction(
  (s) => s.personalFiles,
  (personalFiles, draft) => {
    const hasPending = hasFilesInProgress(personalFiles);
    draft.hasPendingFiles = hasPending;

    if (hasPending) {
      pendingFilesContexts = filterFilesInProgress(personalFiles).map(
        (file) => file.id,
      );
      getPersonalFiles();

      if (!fileInterval) {
        logger.info('User has pending files');
        fileInterval = setInterval(() => {
          getPersonalFiles();
        }, FILE_POLLING_INTERVAL);
      }
    } else {
      clearInterval(fileInterval);
      fileInterval = undefined;
      appendToFileContexts(pendingFilesContexts);
      pendingFilesContexts = [];
      logger.info('User has no pending files');
    }
  },
);

export const loginAction = async () => {
  UserStore.update((s) => {
    s.requestedAuth = true;
    return s;
  });
};

export const setUserHasLoaded = async (
  isAuthed: boolean,
  apiConfig: any,
  accountInfo: any,
) => {
  const agreedToTerms = await retrieveSessionValue('agreedToTerms');
  localStorage.setItem(
    LocalStorageKey.localAccountId,
    accountInfo?.localAccountId,
  );

  UserStore.update((s) => {
    s.isAuthed = isAuthed;
    s.apiConfig = apiConfig;
    s.userHasLoaded = true;
    s.userInfo = {
      name: accountInfo?.name || '',
      email: accountInfo?.username || '',
      initials: getInitials(accountInfo?.name || ''),
    };
    s.agreedToTerms = agreedToTerms === 'true';
    return s;
  });

  persistSessionValue('isAuthed', 'true');
};

export const logoutAction = async () => {
  persistSessionValue('isAuthed', null);

  UserStore.update((s) => {
    s.requestedAuth = false;
    s.apiConfig = null;
    s.isAuthed = false;
    s.currentSession = '';
    s.viewedSplash = false;
    return s;
  });

  await setAgreedToTerms(false);
};

export const getPersonalFiles = async () => {
  try {
    const files = await api.getFileList();
    if (files && Array.isArray(files)) {
      UserStore.update((s) => {
        s.personalFiles = files;
        return s;
      });
    }
  } catch (e) {
    logger.error('Could not fetch personal files', e);
  }
};

export const setAgreedToTerms = async (agreedToTerms: boolean) => {
  persistSessionValue('agreedToTerms', agreedToTerms.toString());
  UserStore.update((s) => {
    s.agreedToTerms = agreedToTerms;
    return s;
  });
};

export const setViewedSplash = async (viewedSplash: boolean) => {
  persistSessionValue('viewedSplash', viewedSplash.toString());
  UserStore.update((s) => {
    s.viewedSplash = viewedSplash;
    return s;
  });
};

export const updateDocumentsRemaining = (documentsRemaining: number) => {
  const currentDocumentsRemaining = UserStore.getRawState().documentsRemaining;

  UserStore.update((s) => {
    s.documentsRemaining = documentsRemaining;
    return s;
  });

  if (
    currentDocumentsRemaining === FILE_UPLOAD_MAXIMUM_WARNING + 1 &&
    documentsRemaining === FILE_UPLOAD_MAXIMUM_WARNING
  ) {
    const message = 'You are 5 uploads away from your limit.';
    logger.info('User was warned about file limit');
    return message;
  }
  if (currentDocumentsRemaining === 1 && documentsRemaining === 0) {
    const message = 'You have reached your monthy upload limit.';
    logger.info('User was warned about hitting the file limit');
    return message;
  }
  return '';
};

(async () => {
  const isAuthed = await retrieveSessionValue('isAuthed');
  const agreedToTerms = await retrieveSessionValue('agreedToTerms');
  const viewedSplash = await retrieveSessionValue('viewedSplash');
  if (isAuthed === 'true') {
    UserStore.update((s) => {
      s.isAuthed = true;
      s.agreedToTerms = agreedToTerms === 'true';
      s.viewedSplash = viewedSplash === 'true';
      return s;
    });
  }
})();
