import { Store } from 'pullstate';

type GlobalMessageState = {
  message?: string;
  type?: 'success' | 'error' | 'permanent';
};

const initialState: GlobalMessageState = {
  message: undefined,
  type: undefined,
};

export const setGlobalError = async (message: string) => {
  GlobalMessageStore.update((s) => {
    return {
      message: message,
      type: 'error',
    };
  });
};

export const setGlobalMessage = async (message: string, type = 'success') => {
  GlobalMessageStore.update((s) => {
    return {
      message: message,
      type: type,
    };
  });
};

export const clearGlobalMessage = async () => {
  GlobalMessageStore.update((s) => {
    return initialState;
  });
};

export const GlobalMessageStore = new Store(initialState);
