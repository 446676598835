import * as React from 'react';
import { Select as AntdSelect } from 'antd';

import './select.css';

const Select = ({ placeholder, options, ...rest }) => {
  return (
    <div className="select">
      <AntdSelect
        options={[...options]}
        placeholder={placeholder}
        {...rest}
      ></AntdSelect>
    </div>
  );
};

export default Select;
