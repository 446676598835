import './stryker-logo.css';
import { ReactComponent as StrykerLogoColor } from './stryker-logo.svg';
import { ReactComponent as StrykerLogoMonochrome } from './stryker-logo-monochrome.svg';

const StrykerLogo = ({ monochrome = false }) => {
  return (
    <div className="stryker-logo">
      {monochrome ? <StrykerLogoMonochrome /> : <StrykerLogoColor />}
    </div>
  );
};

export default StrykerLogo;
