export function countStringNewLines(str: string = ''): number {
  const matches = str.match(/\r\n|\n|\r/g);
  return matches ? matches.length : 0;
}

export function formatNewLinesWithBr(str: string = ''): string {
  return str.replace(/\n/g, '<br>') || str;
}

export function formatNewLinesWithSpace(str: string = ''): string {
  return str.replace(/\n/g, '') || str;
}

export function encodeStringToHtmlEntities(str: string = ''): string {
  return str
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');
}

export function decodeStringFromHtmlEntities(str: string = ''): string {
  return str
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"')
    .replace(/&#039;/g, "'")
    .replace(/&amp;/g, '&');
}

export function stripHtmlFromString(str: string = ''): string {
  return str.replace(/<[^>]*>/g, '');
}
