import classnames from 'classnames';

import './drawer.css';

const Drawer = ({ children, isCollapsed = false }) => {
  return (
    <aside
      data-testid="drawer"
      className={classnames('drawer', { '-collapsed': isCollapsed })}
    >
      {!isCollapsed && <>{children}</>}
    </aside>
  );
};

export default Drawer;
