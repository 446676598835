import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import config from './config';
import { QueryClient, QueryClientProvider } from 'react-query';

import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const queryClient = new QueryClient();

const msal = new PublicClientApplication({
  auth: {
    clientId: config.clientId,
    postLogoutRedirectUri: `${window.location.origin}/signedout`,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
});

root.render(
  <MsalProvider instance={msal}>
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </React.StrictMode>
  </MsalProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
