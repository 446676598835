import React from 'react';
import { Button } from 'ui/atoms';

import { ReactComponent as ResubmitIcon } from './resubmit.svg';
import './resubmitbutton.css';

const ResubmitButton = ({ resubmitAction }) => {
  return (
    <div className="message resubmit-button">
      <div className="action">
        <Button
          className="error-message"
          icon={<ResubmitIcon />}
          onClick={resubmitAction}
        >
          Resend Query
        </Button>
      </div>
    </div>
  );
};

export default ResubmitButton;
