import { Store } from 'pullstate';
import { Theme } from 'ui/organisms/Settings/settings-menu-modal';
import ApiService from 'services/api';
import { LocalStorageKey } from 'utils/localstorage';

type AppContext = {
  hasLoaded: boolean;
  isHaltAndCatchFire: boolean;
  isSidebarCollapsed: boolean;
  theme: Theme;
  apiVersion?: string;
  deviceWidth: string;
  isMessagesWindowScrolling: boolean;
  isSettingsMenuOpen: boolean;
};

const initialState: AppContext = {
  hasLoaded: false,
  isHaltAndCatchFire: false,
  isSidebarCollapsed: false,
  theme: 'light',
  apiVersion: undefined,
  deviceWidth: calculateDeviceWidth(),
  isMessagesWindowScrolling: false,
  isSettingsMenuOpen: false,
};

export const AppContextStore = new Store(initialState);

export const resetAppContext = () => {
  AppContextStore.update(() => {
    return { ...initialState };
  });
};

export const setHaltAndCatchFire = (isCriticalError) => {
  AppContextStore.update((s) => {
    s.isHaltAndCatchFire = isCriticalError;
    return s;
  });
};

export const setHasLoaded = (hasLoaded) => {
  AppContextStore.update((s) => {
    s.hasLoaded = hasLoaded;
    return s;
  });
};

export const setIsSidebarCollapsed = (isSidebarCollapsed, executeOn = '') => {
  const { deviceWidth } = AppContextStore._getInitialState();

  if (executeOn.length && executeOn !== deviceWidth) {
    return;
  }

  AppContextStore.update((s) => {
    s.isSidebarCollapsed = isSidebarCollapsed;
    return s;
  });
};

export const setTheme = (theme: Theme) => {
  localStorage.setItem(LocalStorageKey.theme, theme);

  AppContextStore.update((s) => {
    s.theme = theme;
    return s;
  });
};

export const setIsMessagesWindowScrolling = (isScrolling: boolean) => {
  AppContextStore.update((s) => {
    s.isMessagesWindowScrolling = isScrolling;
    return s;
  });
};

export const initApiVersion = async () => {
  const apiVersion = await ApiService.getApiVersion();
  AppContextStore.update((s) => {
    s.apiVersion = apiVersion;
    return s;
  });
};

export function calculateDeviceWidth(): string {
  const breakpoints = {
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    '2xl': 1536,
  };

  const deviceWidth: number =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  let currentBreakpoint = '';

  for (const breakpoint in breakpoints) {
    if (breakpoints.hasOwnProperty(breakpoint)) {
      const breakpointWidth = breakpoints[breakpoint];

      if (deviceWidth < breakpointWidth) {
        currentBreakpoint = breakpoint;
        break;
      }
    }
  }

  return currentBreakpoint;
}

export const toggleSettingsMenuOpen = () => {
  return AppContextStore.update((s) => {
    s.isSettingsMenuOpen = !s.isSettingsMenuOpen;
    return s;
  });
};

export const setSettingsMenuOpen = (isOpen) => {
  return AppContextStore.update((s) => {
    s.isSettingsMenuOpen = isOpen;
    return s;
  });
};
