import React from 'react';
import { Badge as AntBadge } from 'antd';

import './badge.css';

const Badge = ({ children, count }) => {
  return (
    <AntBadge count={count} color="#faad14">
      {children}
    </AntBadge>
  );
};

export default Badge;
