import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Modal, Button } from 'ui/atoms';

import './confirmation-dialog.css';

const ConfirmationDialog = ({
  open,
  text,
  confirmText,
  cancelText,
  reportResponse,
  confirmDanger = true,
  maskClosable = true,
  title = '',
  onCancelCallback = () => {},
}) => {
  const [response, setResponse] = useState<boolean | undefined>();

  useEffect(() => {
    if (response !== undefined) {
      reportResponse(response);
    }
  }, [response, reportResponse]);

  return (
    <Modal
      title={title}
      className="confirmation-dialog"
      open={open}
      onOk={() => {
        setResponse(true);
      }}
      onCancel={() => {
        setResponse(false);
      }}
      closeIcon={false}
      maskClosable={maskClosable}
      children={<div className="confirmation-dialog-text">{text}</div>}
      footer={[
        <Button
          key="confirm_cancel"
          type="outline"
          onClick={() => {
            onCancelCallback();
            setResponse(false);
          }}
        >
          {cancelText}
        </Button>,
        <Button
          key="confirm_confirm"
          type="default"
          className={classNames(['button-confirm', { danger: confirmDanger }])}
          onClick={() => setResponse(true)}
        >
          {confirmText}
        </Button>,
      ]}
    ></Modal>
  );
};

export default ConfirmationDialog;
