import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import useSilentRequest from 'services/hooks/useSilentRequest';
import { loginAction, setViewedSplash, UserStore } from 'stores/user';
import { Button, GoldieLogo, Loading, StrykerLogo } from 'ui';

import './login.css';

const Login = () => {
  let navigate = useNavigate();
  let { account } = useSilentRequest();
  const isAuthed = UserStore.useState((s) => s.isAuthed);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isAuthed) {
      setViewedSplash(true);
      navigate('/terms');
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, [account, isAuthed, navigate]);

  const login = () => {
    document.querySelector('.ant-notification-notice-wrapper')?.remove();

    loginAction().then(() => {
      navigate('/terms');
    });
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="login">
      <div className="login-content">
        <StrykerLogo />
        <GoldieLogo />
        <Button onClick={login}>Sign in with Stryker</Button>
      </div>
    </div>
  );
};

export default Login;
