import { Input } from 'antd';
import React from 'react';

import './productassociation.css';

const ProductAssociation = ({ onProductSelect, value }) => {
  return (
    <div className="product-association">
      <label htmlFor="input-product-name">
        What product is this associated with?
      </label>
      <Input
        id="input-product-name"
        placeholder="Type product name here"
        value={value}
        onChange={(e) => {
          onProductSelect(e.target.value);
        }}
      />
    </div>
  );
};

export default ProductAssociation;
