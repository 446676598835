import React, { FC } from 'react';
import classNames from 'classnames';

import './dashed-area.css';

type DashedAreaPropsType = {
  children: React.ReactNode;
  buttons?: React.ReactNode[];
  type?: 'default' | 'success' | 'error';
};

const DashedArea: FC<DashedAreaPropsType> = ({ children, buttons, type }) => {
  return (
    <div
      className={classNames([
        'dashed-area',
        { '-success': type === 'success' },
        { '-error': type === 'error' },
      ])}
    >
      {children}
      {buttons && (
        <div className="dashed-area-buttons">{buttons.map((b) => b)}</div>
      )}
    </div>
  );
};

export default DashedArea;
