import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserStore, setViewedSplash } from 'stores/user';
import { Button, Loading, StrykerLogo } from 'ui';

import './splash.css';

const Splash = () => {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const isAuthed = UserStore.useState((s) => s.isAuthed);
  const viewedSplash = UserStore.useState((s) => s.viewedSplash);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [isAuthed, viewedSplash, navigate]);

  const navigateToLogin = () => {
    navigate('/login');
    setViewedSplash(true);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="splash">
      <section className="splash-content">
        <StrykerLogo />
        <h1>
          Welcome to <span className="goldie-emphasis">Goldie</span>,
        </h1>
        <p>Stryker's in-house AI chatbot</p>
        <p>
          Your use and feedback of this app will help towards its continued
          improvement and development.
        </p>
        <Button onClick={navigateToLogin}>Continue</Button>
      </section>
      <div className="gradient-underlay" />
    </div>
  );
};

export default Splash;
