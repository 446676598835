import './goldie-logo.css';
import { ReactComponent as GoldieLogoColor } from './goldie-logo.svg';
import { ReactComponent as GoldieLogoMonochrome } from './goldie-logo-monochrome.svg';

const GoldieLogo = ({ monochrome = false }) => {
  return (
    <div className="goldie-logo">
      {monochrome ? <GoldieLogoMonochrome /> : <GoldieLogoColor />}
    </div>
  );
};

export default GoldieLogo;
