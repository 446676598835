import { datadogLogs } from '@datadog/browser-logs';

import config from 'config';
import { AuthStore } from 'stores/auth';
import { LocalStorageKey } from 'utils/localstorage';
import { VERSION } from 'version';
const { datadogClientToken = '', datadogSite = '' } = config;

datadogLogs.init({
  clientToken: datadogClientToken,
  site: datadogSite,
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
  silentMultipleInit: true,
});

export function getEnvironmentFromHost() {
  const host = window.location.host;

  if (config.env === 'development') {
    return 'LOCAL';
  }

  const patterns = [
    { pattern: /\.tst\./, environment: 'TST' },
    { pattern: /\.scr\./, environment: 'SCR' },
    { pattern: /\.tmp\./, environment: 'TMP' },
    { pattern: /\.dev\./, environment: 'DEV' },
    { pattern: /\.qa\./, environment: 'QA' },
  ];

  for (const { pattern, environment } of patterns) {
    if (pattern.test(host)) {
      return environment;
    }
  }

  return 'PRD';
}

const logMethods = {};

['debug', 'info', 'error'].forEach((fn) => {
  logMethods[fn] = (...logs: [string, object]) => {
    const [message, options = {}] = logs;
    const { currentAccount } = AuthStore.getRawState();

    const localAccountId =
      currentAccount?.localAccountId ||
      localStorage.getItem(LocalStorageKey.localAccountId);

    const defaultOptions = {
      service: 'aida-web',
      env: getEnvironmentFromHost(),
      app_version: VERSION,
      aida_session_id: localStorage.getItem(LocalStorageKey.sessionId),
      aida_account_id: localAccountId,
    };

    if (config.env === 'development') {
      return;
    }

    try {
      datadogLogs.logger[fn](message, {
        ...defaultOptions,
        ...options,
      });
    } catch (error) {
      console.error('There was an error sending logs to Datadog.');
    }
  };
});

export const DataDogLog = {
  log: logMethods['info'],
  info: logMethods['info'],
  error: logMethods['error'],
  debug: logMethods['debug'],
};

export const sendAnalytics = {
  userLogout: () => {
    DataDogLog.info('user logout');
  },
  userLogin: () => {
    DataDogLog.info('user login');
  },
};
