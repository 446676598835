import { Store } from 'pullstate';

export enum Features {
  IdleTimeout = 'IdleTimeout',
}

const initialState = {
  [Features.IdleTimeout]: false,
};

export const FeaturesStore = new Store(initialState);

export const initializeFeatures = async (features: Record<string, boolean>) => {
  return FeaturesStore.update((s) => {
    return {
      ...s,
      ...features,
    };
  });
};

export function isFeatureEnabled(feature: Features): boolean {
  const state = FeaturesStore.getRawState();
  return state[feature];
}
