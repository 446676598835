import ls from 'localstorage-slim';

const DEFAULT_TTL = 60 * 1; // 1 min
export const DEFAULT_TOKEN_TTL = 32400; // 9 hours

export async function persistValue(key, value, ttl = DEFAULT_TTL) {
  try {
    if (value === null) {
      let result = await clearValue(key);
      return result;
    } else {
      const result = await ls.set(key, value, { ttl });
      return result;
    }
  } catch (ex) {
    return false;
  }
}

export async function retrieveValue(key) {
  try {
    const value = await ls.get(key);
    return value || null;
  } catch (ex) {
    return null;
  }
}

export function retrieveValueSync(key) {
  try {
    const value = ls.get(key);
    return value || null;
  } catch (ex) {
    return null;
  }
}

export async function clearValue(key) {
  try {
    const result = await ls.remove(key);
    return result;
  } catch (ex) {
    return false;
  }
}

export async function clearCache() {
  await ls.flush(true);
  return ls.clear();
}

export async function persistSessionValue(key, value) {
  try {
    return sessionStorage.setItem(key, value);
  } catch (ex) {
    return false;
  }
}

export async function retrieveSessionValue(key) {
  try {
    return sessionStorage.getItem(key);
  } catch (ex) {
    return null;
  }
}
