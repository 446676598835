import React from 'react';
import { Button, Modal } from '../../atoms';

import FileUpload from './FileUpload';
import {
  FileUploadStore,
  deleteFile,
  resetStore as resetFileStore,
  saveMetadata,
} from 'stores/file-upload';
import {
  UserStore,
  getPersonalFiles,
  updateDocumentsRemaining,
} from 'stores/user';
import { setGlobalMessage } from 'stores/global-message';
import { AccessLevel, DOCUMENT_UPLOAD_LIMIT } from 'stores/metadata';

const FileUploadModal = ({ fileUploadVisible, setFileUploadVisible }) => {
  const isValid = FileUploadStore.useState((s) => s.isValid);
  const hasError = FileUploadStore.useState((s) => s.hasError);
  const fileId = FileUploadStore.useState((s) => s.fileId);
  const uploadComplete = FileUploadStore.useState((s) => s.uploadComplete);
  const accessLevel = FileUploadStore.useState((s) => s.accessLevel);

  const [hasSaved, setHasSaved] = React.useState(false);

  const documentsRemaining = UserStore.useState((s) => s.documentsRemaining);

  return (
    <Modal
      title={
        <>
          Upload File
          <div className="tiny">{`${documentsRemaining}/${DOCUMENT_UPLOAD_LIMIT} file quota`}</div>
        </>
      }
      open={fileUploadVisible}
      onOk={() => {
        setFileUploadVisible(false);
        resetFileStore();
      }}
      onCancel={() => {
        if (!hasSaved) {
          if (fileId) {
            deleteFile(fileId);
          }
        }
        setFileUploadVisible(false);
        resetFileStore();
      }}
      footer={[
        <Button
          key="fileupload_cancel"
          type="outline"
          onClick={() => {
            if (fileId) {
              deleteFile(fileId);
            }
            setFileUploadVisible(false);
            resetFileStore();
          }}
        >
          Cancel
        </Button>,
        <Button
          key="fileupload_apply"
          type="default"
          className={`${isValid && uploadComplete ? 'active' : 'unavailable'}`}
          disabled={!isValid || hasError || !uploadComplete}
          onClick={() => {
            const shouldThank = accessLevel === AccessLevel.Public;
            setHasSaved(true);
            saveMetadata();
            setFileUploadVisible(false);
            getPersonalFiles();
            resetFileStore();

            const remainingFiles = 100;
            let globalMessage = '';

            if (shouldThank) {
              globalMessage =
                'Thank you for taking the time to improve Goldie! ';
            }
            const fileLimitWarning = updateDocumentsRemaining(remainingFiles);
            if (fileLimitWarning) {
              globalMessage = `${globalMessage}${fileLimitWarning}`;
            }
            if (globalMessage) {
              setGlobalMessage(globalMessage);
            }
          }}
        >
          Save File
        </Button>,
      ]}
    >
      <FileUpload />
    </Modal>
  );
};

export default FileUploadModal;
