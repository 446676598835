import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Card, Loading } from 'ui';
import { openPublicFile } from 'utils/open-public-file';
import { UserStore, setAgreedToTerms, NIL_UUID } from 'stores/user';
import { sendAnalytics } from 'services/logs/datadog';

import './terms.css';
import { LocalStorageKey } from 'utils/localstorage';

const ExternalAgreementLinks = {
  coc: 'https://stryker.sharepoint.com/sites/CodeofConduct',
  aup: 'https://stryker.sharepoint.com/sites/StrykerPolicyPortal/PortalDocuments/Forms/Expansion%20View.aspx?FilterField1=Business%5Fx0020%5FFunction&FilterValue1=Information%20Technology&FilterType1=Choice&FilterDisplay1=Information%20Technology&FilterField2=Document%5Fx0020%5FCategory&FilterValue2=ACCEPTABLE%20USE&FilterType2=Text&FilterDisplay2=ACCEPTABLE%20USE&viewid=83b05d7d%2D738e%2D4d87%2D8ad1%2D2293e1d47297',
};

const Terms = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const agreedToTerms = UserStore.useState((s) => s.agreedToTerms);
  const viewedSplash = UserStore.useState((s) => s.viewedSplash);

  useEffect(() => {
    if (agreedToTerms) {
      setIsLoading(false);
      return navigate('/');
    }

    if (!viewedSplash) {
      setIsLoading(false);
      return navigate('/splash');
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, [agreedToTerms, navigate, viewedSplash]);

  const proceed = () => {
    setAgreedToTerms(true);

    let currentSessionId = localStorage.getItem(LocalStorageKey.sessionId);

    if (currentSessionId && currentSessionId !== NIL_UUID) {
      return navigate('/');
    }

    currentSessionId = crypto.randomUUID();
    localStorage.setItem(LocalStorageKey.sessionId, currentSessionId);

    sendAnalytics.userLogin();
    navigate('/');
  };

  const notices = [
    {
      title: 'Goldie sometimes makes mistakes.',
      desc: 'You are responsible for checking its accuracy.',
    },
    {
      title: 'Use of Goldie must adhere to all corporate policies,',
      desc: `including Stryker’s <a target="_blank" href="${ExternalAgreementLinks.coc}">Code of Conduct</a> and <a target="_blank" href="${ExternalAgreementLinks.aup}">Acceptable Use Policy</a>.`,
    },
    {
      title: 'Do not use Goldie with sensitive content.',
      desc: 'Do not upload files or enter prompts that contain sensitive personal information, such as information relating to an individual’s pay and compensation, religious beliefs, sex life or sexual orientation, or confidential financial information such as government ID or credit card numbers.',
    },
    {
      title:
        'Usage data is collected for compliance, safety, and to improve future versions of Goldie.',
      desc: 'See the privacy notice for more information.',
    },
  ];

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="terms">
      <div className="card-wrapper">
        <Card>
          <article>
            <h1>Notice</h1>
            {notices.map((notice) => {
              return (
                <section className="notice" key={notice.title}>
                  <h4>{notice.title}</h4>
                  <p dangerouslySetInnerHTML={{ __html: notice.desc }}></p>
                </section>
              );
            })}
          </article>
          <div className="notice-proceed">
            <p>
              By clicking proceed, you are agreeing to the&nbsp;
              <Button
                className="button-inline-link"
                onClick={() => openPublicFile('goldie-terms-of-use.pdf')}
              >
                Terms of Use&nbsp;
              </Button>
              and the&nbsp;
              <Button
                className="button-inline-link"
                onClick={() => openPublicFile('goldie-privacy-notice.pdf')}
              >
                Privacy Notice
              </Button>
              .
            </p>
            <Button className="proceed" onClick={() => proceed()}>
              Proceed
            </Button>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Terms;
