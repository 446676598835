import { useEffect } from 'react';
import { sendAnalytics } from 'services/logs/datadog';
import { setGlobalMessage } from 'stores/global-message';
import { NIL_UUID, setViewedSplash } from 'stores/user';
import { LocalStorageKey } from 'utils/localstorage';

const PermanentToastPage = ({ children, message }) => {
  const theme = localStorage.getItem(LocalStorageKey.theme) ?? 'light';

  useEffect(() => {
    if (localStorage.getItem(LocalStorageKey.sessionId) === NIL_UUID) {
      return;
    }

    sendAnalytics.userLogout();
    localStorage.setItem(LocalStorageKey.sessionId, NIL_UUID);
  }, []);

  useEffect(() => {
    document.body.classList.add(`-theme-${theme}`);
    setGlobalMessage(message, 'permanent');
    setViewedSplash(true);
  }, [message, theme]);

  return <>{children}</>;
};

export default PermanentToastPage;
